import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		component: () => import('@/layouts/DefaultLayout.vue'),
		children: [
			{ path: '', name: 'Home', component: () => import('@/views/HomePage.vue') },
			{ path: 'login', name: 'Login', component: () => import('@/views/LoginPage.vue') },
			{ path: 'sign-up', name: 'SignUp', component: () => import('@/views/SignUpPage.vue') },
			{ path: 'help', name: 'Help', component: () => import('@/views/HelpPage.vue') },
			{ path: 'lending', name: 'Lending', component: () => import('@/views/LendingPage.vue') },
			{ path: 'borrowing', name: 'Borrowing', component: () => import('@/views/BorrowingPage.vue') },
			{ path: 'community', name: 'Community', component: () => import('@/views/CommunityPage.vue') },
			{ path: 'my-neobanx', name: 'MyNeobanx', component: () => import('@/views/MyNeobanxPage.vue') },
			{ path: 'about-neobanx', name: 'AboutNeobanx', component: () => import('@/views/AboutNeobanxPage.vue') },
			{ path: 'contact-us', name: 'ContactUs', component: () => import('@/views/ContactUsPage.vue') },
			{ path: 'terms-of-use', name: 'TermsOfUse', component: () => import('@/views/TermsOfUsePage.vue') },
			{ path: 'privacy-policy', name: 'PrivacyPolicy', component: () => import('@/views/PrivacyPolicyPage.vue') },
			{ path: 'invite', name: 'Invite', component: () => import('@/views/InvitePage.vue') }
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
