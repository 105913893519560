import Vue from 'vue'

import './styles/quasar.scss'
import iconSet from 'quasar/icon-set/material-icons.js'
import '@quasar/extras/material-icons/material-icons.css'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';
import VueCompositionApi from '@vue/composition-api'

Vue.use(Quasar, {
	config: {},
	plugins: {
		Notify,
		Loading
	},
	iconSet: iconSet
 })

 Vue.use(VueCompositionApi)