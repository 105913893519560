import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	strict: true,
	state: {
		loginInfo: {
			firstName: ''
		}
	},
	mutations: {
		UPDATE_LOGIN_INFO(state, loginInfo) {
			state.loginInfo = loginInfo
			sessionStorage.setItem('loginInfo', JSON.stringify(loginInfo))
		},

		RESET_LOGIN_INFO(state) {
			state.loginInfo = {
				firstName: ''
			}

			sessionStorage.setItem('loginInfo', JSON.stringify(state.loginInfo))
		}
	},
	actions: {
		updateLoginInfo({ commit }, loginInfo) {
			commit('UPDATE_LOGIN_INFO', loginInfo)
		},

		resetLoginInfo({ commit }) {
			commit('RESET_LOGIN_INFO')
		}
	},
	getters: {
		loginInfo: state => {
			const tmp = JSON.parse(sessionStorage.getItem('loginInfo')!)

			return tmp == null ? state.loginInfo : tmp
		}
	}
})
